import './index.scss'

// $(document).scroll(function () { 
//     var scroH = $(document).scrollTop();  
//     var viewH = $(window).height();        
//     var contentH = $(document).height();       
//     if(scroH >100){          
//     }         
//     if (contentH - (scroH + viewH) <= 100){                     
//     }           
//     if (contentH = (scroH + viewH)){                    
//     }         
// });

//pc端鼠标经过
let $headershadowEl = $('.header')
$('.header .nav>ul').on('mouseleave', function () {
    $headershadowEl.css({
        'padding-bottom': '10px',
        'background-color': 'transparent'
    });
    $('.masklklksas').fadeOut(100)
});

var mouseover_tid = [];
var mouseout_tid = [];
$(document).ready(function () {
  $(".header .nav>ul>li").each(function (index) {
    $(this).hover(
      // 鼠标进入
      function () {
        var _self = this;
        // 停止卷起事件
        clearTimeout(mouseout_tid[ index ]);
        // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
        mouseover_tid[ index ] = setTimeout(function () {
            $(_self).addClass('hover')
        let $sub = $(_self).find('.sub-nav');
        let shadowH = $headershadowEl.height()
        if ($sub.length > 0 && shadowH != $sub.css('height')) {
            $headershadowEl.css({
                'padding-bottom': $sub.css('height'),
                'background-color': '#fff'
            });
            $('.masklklksas').fadeIn(100)
            // $('.trans-cont').css({
            //     "opacity":".8",
            //     "z-index":"99",
            // })
        } else if ($sub.length > 0 && shadowH != 0) {
        } else if ($sub.length == 0) {
            $headershadowEl.css({
                'padding-bottom': '10px',
                'background-color': 'transparent'
            });
            $('.masklklksas').fadeOut(100)
        }
        }, 201);
      },
      // 鼠标离开
      function () {
        var _self = this;
        // 停止展开事件
        clearTimeout(mouseover_tid[ index ]);
        // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
        mouseout_tid[ index ] = setTimeout(function () {
            $(_self).removeClass('hover')
        }, 201);
      });
    });
});



// 头部搜索隐藏显示
$('.header .search-icon').on('click', function () {
    $('.header-search').addClass('show')
})
$('.header-search .search-close .iconfont').on('click', function () {
    $('.header-search').removeClass('show')
})

//侧栏菜单隐藏显示
$('[data-open="cmenu"]').on('click', function () {
    if ($('.header .hamburger').hasClass('active')) {
        $('.header .hamburger').removeClass('active')
        $('.mob-nav').removeClass('active')
        $('.comp-root').removeClass('trans')
        $('.trans-cont').removeClass('trans')

    } else {
        $('.header .hamburger').addClass('active')
        $('.mob-nav').addClass('active')
        $('.comp-root').addClass('trans')
        $('.trans-cont').addClass('trans')
    }
})
$('.mob-nav .nav-title .iconfont').on('click', function () {
    let $parentsEl = $(this).parents('li')
    let isActive = $parentsEl.hasClass('active')

    if (isActive) {
        $parentsEl.removeClass('active').find('.sub-nav').slideUp(200)
    } else {
        $parentsEl.siblings().removeClass('active').find('.sub-nav').slideUp(200)
        $parentsEl.addClass('active').find('.sub-nav').slideDown(200)
    }
})

//pc端鼠标经过效果
// $(".header .pc-nav ul li").mouseenter(function () {
//     if (!$(this).hasClass('active')) {
//         $(this).addClass('hover');
//         $(this).siblings('.active').addClass('not-active');
//     }
// }).mouseleave(function () {
//     if (!$(this).hasClass('active')) {
//         $('.header .pc-nav ul li').removeClass('hover');
//         $('.header .pc-nav ul li.active').removeClass('not-active');
//     }
// });

//移动端有子菜单的显示箭头
// $('header .mob-nav ul li').each(function () {
//     if ($(this).find('.sub-nav a').length > 0) {
//         $(this).find('.nav-title .iconfont').addClass('show')
//     }
// })
//点击移动端导航列表展开子项
// $('header .mob-nav ul li .nav-title .iconfont').on('click', function () {
//     if (!$(this).parents('li').hasClass('active') && $(this).parents('li').find('.sub-nav a').length != 0) {
//         $(this).parents('li').addClass('active').siblings().removeClass('active')
//         $(this).parents('li').find('.sub-nav').slideDown(300)
//         $(this).parents('li').siblings().find('.sub-nav').slideUp(300)
//     }
// })

//表单动态横线
// $('.exhibitor-reserve .right-form input').focus(function () {
//     if (!$(this).parents('.form-item').hasClass('active')) {
//         $(this).parents('.form-item').addClass('active')
//     }
// }).blur(function () {
//     if ($(this).val() == '') {
//         $(this).parents('.form-item').removeClass('active')
//     }
// })