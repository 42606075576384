module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!title) var title = '';
;
__p += '\r\n\r\n<section class="quality-banner">\r\n    <div class="banner-img" style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></div>\r\n    <div class="container">\r\n        <div class="banner-title">\r\n            <div class="breadcrumb">\r\n                <a href="#">首页</a><span>-</span>\r\n                <a href="#">关于金宇</a>\r\n            </div>\r\n            <div class="title">' +
((__t = ( title )) == null ? '' : __t) +
'</div>\r\n        </div>\r\n    </div>\r\n</section>';

}
return __p
}