module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!title1) var title1 = '';
if (!title2) var title2 = '';
;
__p += '\r\n<section class="about-top-title">\r\n    <div class="container">\r\n        <div class="left-text">\r\n            <h1>' +
((__t = ( title1 )) == null ? '' : __t) +
'</h1>\r\n            <div>' +
((__t = ( title2 )) == null ? '' : __t) +
'</div>\r\n        </div>\r\n        <div class="breadcrumb">\r\n            <a href="#">首页</a><span>-</span>\r\n            <a href="#">关于金宇</a>\r\n        </div>\r\n    </div>\r\n</section>';

}
return __p
}